import { ContentBlockType } from '@tytapp/content';
import { ContinueWatchingBlock } from './continue-watching-block';

export * from './continue-watching-block';

export const CONTINUE_WATCHING_BLOCK_TYPE: ContentBlockType<ContinueWatchingBlock> = {
    id: 'continue-watching',
    label: 'Continue Watching',
    icon: 'watch_later',
    editable: false,
    template: {
    },
    preview: {
    },
    deferredEditorComponent: () => import('./continue-watching-block.edit').then(m => m.EditorComponent),
    deferredViewComponent: () => import('./continue-watching-block.view').then(m => m.ViewComponent)
}
