<tyt-content-card [title]="title ?? topic.topic.name" [icon]="icon">
  <div class="actions">
    @if (topic.topic) {
      <tyt-follow-button type="CMS::Topic" [id]="topic.topic.id" />
      <a mat-button url="/topics/{{topic.topic.slug ?? topic.topic.id}}">
          <mat-icon>open_in_new</mat-icon>
          More
      </a>
    }
  </div>

  @if (placeholder) {
    <div class="home-row-items">
      @for (n of [].constructor(expectedCount); track n) {
        <a class="home-row-item">
          <div class="thumbnail-box">
            <div></div>
            <div class="content-info">
              <div class="brand-icon"></div>
              <div class="title-row-info">
                <div class="title">
                  <span class="placeholder"></span>
                </div>
                <time><span class="placeholder"></span></time>
              </div>
            </div>
          </div>
          <div class="title-row">
            <div class="title-row-info">
              <div class="description">
                <span class="placeholder"></span>
              </div>
              <div class="tagline">
                <span class="placeholder"></span>
                <!-- <tyt-content-badge [type]="badgeTypeForTopicItem(item)" [unlocked]="item.premium_unlocked"
              [premium]="item.premium"></tyt-content-badge> -->
              <ul class="facts">
                <li><span class="placeholder"></span></li>
                <li>
                  <span class="placeholder" style="width: 2em"></span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </a>
    }
  </div>
  } @else {
    <div class="home-row-items">
      @for (item of topic.topic_items; track item; let i = $index) {
        <a
          [routerLink]="linkForTopicItem(item)"
          class="home-row-item"
          [attr.data-shutter-order]="i"
          >
          <div class="thumbnail-box" [responsiveBackgroundImage]="item.thumbnail" [widthFactor]="0.33">
            <div></div>
            <div class="content-info">
              <div class="brand-icon" [responsiveBackgroundImage]="item.brand_logo" [widthFactor]="0.1"
              [mobileWidthFactor]="0.1"></div>
              <div class="title-row-info">
                <div class="title">{{item.brand_name}}</div>
                <time [attr.datetime]="item.date">{{item.date | date: 'longDate'}}</time>
              </div>
            </div>
            @if (item.type === 'vod') {
              <tyt-playback-status-bar [playlist]="playlistForTopicItem(item)"
              [itemId]="item.vod_id"></tyt-playback-status-bar>
            }
          </div>
          <div class="title-row">
            <div class="title-row-info">
              @if (isValidTitle(item.title)) {
                <div class="description">
                  {{item.title | summarize}}
                </div>
              }
              <div class="tagline">
                <tyt-content-badge [type]="badgeTypeForTopicItem(item)" [unlocked]="item.premium_unlocked"
                [premium]="item.premium"></tyt-content-badge>
                <ul class="facts">
                  @if (item.duration && item.duration > 0) {
                    <li>{{item.duration | duration}}</li>
                  }
                  <li>
                    <tyt-comments-count [identifier]="commentTopicForTopicItem(item)"></tyt-comments-count>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </a>
      }
    </div>
  }
</tyt-content-card>