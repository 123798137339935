import { ContentBlockType } from '@tytapp/content';
import { MustSeeShowsBlock } from './must-see-shows-block';

export * from './must-see-shows-block';

export const MUST_SEE_SHOWS_BLOCK_TYPE: ContentBlockType<MustSeeShowsBlock> = {
    id: 'must-see-shows',
    label: 'Today\'s Must See Shows',
    icon: 'star',
    template: {
    },
    preview: {
    },
    deferredEditorComponent: () => import('./must-see-shows-block.edit').then(m => m.EditorComponent),
    deferredViewComponent: () => import('./must-see-shows-block.view').then(m => m.ViewComponent)
}
