import { Injector } from '@angular/core';
import { ContentBlockType } from '@tytapp/content';
import { TopicsService } from '@tytapp/topics';
import { FollowedTopicBlock } from './followed-topic-block';

export * from './followed-topic-block';

export const FOLLOWED_TOPIC_BLOCK_TYPE: ContentBlockType<FollowedTopicBlock> = {
    id: 'followed-topic',
    label: 'Followed Topics',
    icon: 'favorite',
    template: {

    },
    preview: {
    },
    deferredEditorComponent: () => import('./followed-topic-block.edit').then(m => m.EditorComponent),
    deferredViewComponent: () => import('./followed-topic-block.view').then(m => m.ViewComponent),
    earlyPreloadForViewing(injector: Injector, block: FollowedTopicBlock) {
        // Get the followed topics query started as soon as possible, since it's expensive.
        injector.get(TopicsService).followed();
    }
}
