@if (state === 'loading') {
  <app-spinner style="flex-grow: 1;" [inline]="true"></app-spinner>
}
@if (state === 'ready') {
  <div class="home-row-items">
    @if (playbackSessions) {
      @for (playbackSession of limitArray(playbackSessions, 3); track playbackSession) {
        @for (vod of mainVodArrayOfPlaybackSession(playbackSession); track vod) {
          <a [routerLink]="linkForVod(playbackSession.production, vod)"
            class="home-row-item"
            [attr.data-shutter-order]="$index">
            <div class="title-row">
              <div class="brand-icon"
                [responsiveBackgroundImage]="playbackSession.production.show.logo_square"
              [widthFactor]="0.1" [mobileWidthFactor]="0.1"></div>
              <div class="title-row-info">
                <div class="brand-name">{{playbackSession.production.show.name}}</div>
                <div class="title">
                  {{vod.title | summarize}}
                </div>
                <div class="tagline">
                  <tyt-content-badge type="episode" [unlocked]="unlockedStatusOfVod(vod)"
                  [premium]="vod.is_premium"></tyt-content-badge>
                  <ul class="facts">
                    <li><time>{{playbackSession.production.date | date: 'MMMM d'}}</time></li>
                    @if (vod.duration && vod.duration > 0) {
                      <li>{{vod.duration | duration}}</li>
                    }
                    <li>
                      <tyt-comments-count [identifier]="'vod_' + vod.id"></tyt-comments-count>
                    </li>
                  </ul>
                </div>
                <tyt-playback-status-bar [session]="playbackSession" visibility="always">
                </tyt-playback-status-bar>
              </div>
            </div>
          </a>
        }
      }
    }
  </div>
}