import { NgModule } from '@angular/core';
import { ContentBlocksService, ContentModule } from '@tytapp/content';
import { CONTINUE_WATCHING_BLOCK_TYPE } from './continue-watching-block';
import { MUST_SEE_SHOWS_BLOCK_TYPE } from './must-see-shows-block';
import { ContinueWatchingComponent } from './continue-watching/continue-watching.component';
import { CommonModule } from '@angular/common';
import { TYTCommonModule } from '@tytapp/common';
import { CommonUiModule } from '@tytapp/common-ui';
import { ChatModule } from '@tytapp/chat';
import { RouterModule } from '@angular/router';
import { FOLLOWED_TOPIC_BLOCK_TYPE } from './followed-topic-block';
import { TopicCardComponent } from './topic-card/topic-card.component';
import { SocialModule } from '@tytapp/social';

const COMPONENTS = [
    ContinueWatchingComponent,
    TopicCardComponent
];

@NgModule({
    declarations: COMPONENTS,
    exports: COMPONENTS,
    imports: [
        CommonModule,
        RouterModule,
        TYTCommonModule,
        CommonUiModule,
        ChatModule,
        ContentModule,
        SocialModule
    ]
})
export class MediaUiModule {
    constructor() {
        ContentBlocksService.register(CONTINUE_WATCHING_BLOCK_TYPE);
        ContentBlocksService.register(MUST_SEE_SHOWS_BLOCK_TYPE);
        ContentBlocksService.register(FOLLOWED_TOPIC_BLOCK_TYPE);
    }
}