import { Component, inject } from '@angular/core';
import { ApiPodcast, ApiVOD, UsersApi } from '@tytapp/api';
import { ApiPlaybackSession } from '@tytapp/api/model/PlaybackSession';
import { HomeCard, HomeCardComponentType } from '@tytapp/app/pages/home-page/home-cards.service';
import { LoggerService } from '@tytapp/common';
import { ProductionsService } from '@tytapp/common-ui';
import { UserService } from '@tytapp/user';

@Component({
    selector: 'tyt-continue-watching',
    templateUrl: './continue-watching.component.html',
    styleUrls: ['./continue-watching.component.scss']
})
export class ContinueWatchingComponent implements HomeCardComponentType {
    private userService = inject(UserService);
    private usersApi = inject(UsersApi);
    private productionsService = inject(ProductionsService);
    private logger = inject(LoggerService);

    card: HomeCard;
    title = 'Continue Watching';
    playbackSessions: ApiPlaybackSession[] = null;
    state: 'loading' | 'ready' = 'loading';

    get hidden() {
        return !this.userService.user || this.playbackSessions?.length === 0;
    }

    async ngOnInit() {
        await this.userService.ready;

        this.playbackSessions = null;
        if (this.userService.user) {
            try {
                this.playbackSessions = await this.usersApi.getOpenSessions().toPromise();
            } catch (e) {
                this.logger.error(`Failed to fetch playback sessions:`);
                this.logger.error(e);
                this.playbackSessions = [];
            }
        } else {
            this.playbackSessions = [];
        }

        this.state = 'ready';
    }

    limitArray(array: any[], length) {
        return array.slice(0, length);
    }

    mainVodOfPlaybackSession(playbackSession: ApiPlaybackSession) {

        if (!playbackSession)
            return null;

        if (!playbackSession.production) {
            this.logger.error(`Encountered a playback session (${playbackSession.id}) without a production!`);
            return null;
        }

        let vod: ApiVOD | ApiPodcast;

        if (playbackSession.playlist_id === 'clips') {
            vod = playbackSession.production.vod_clips.find(x => x.id === playbackSession.playlist_item_id);
        } else if (playbackSession.playlist_id === 'episodes') {
            vod = playbackSession.production.full_length_vods.find(x => x.id === playbackSession.playlist_item_id);
        } else if (playbackSession.playlist_id === 'podcasts') {
            vod = playbackSession.production.full_length_podcasts.find(x => x.id === playbackSession.playlist_item_id);
        }

        return vod;
    }

    linkForVod(production, vod) {
        let isEpisode = production.full_length_vods.some(x => x.id === vod.id);
        let mode = isEpisode ? 'episodes' : 'clips';

        return this.productionsService.getWatchUrlSync(production, mode, vod);
    }

    unlockedStatusOfVod(vod: ApiVOD) {
        if (!vod)
            return false;

        if (!vod.unlock_premium)
            return false;

        if (!vod.unlock_premium_expiration)
            return true;


        let date = new Date(vod.unlock_premium_expiration);

        return date.getTime() > Date.now();
    }

    mainVodArrayOfPlaybackSession(playbackSession: ApiPlaybackSession) {
        let vod = this.mainVodOfPlaybackSession(playbackSession);

        if (vod)
            return [vod];

        return [];
    }

}