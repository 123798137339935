import { Component, Input, inject } from '@angular/core';
import { ApiHomeRow, ApiTopicItem } from '@tytapp/api';
import { ArticlesService } from '@tytapp/articles';
import { Icon, Link } from '@tytapp/common';

export interface FollowButton {
    type: string;
    id: string;
}

@Component({
    selector: 'tyt-topic-card',
    templateUrl: './topic-card.component.html',
    styleUrls: ['./topic-card.component.scss']
})
export class TopicCardComponent {
    private articlesService = inject(ArticlesService);

    @Input() topic: ApiHomeRow;
    @Input() title: string;
    @Input() icon: Icon;
    @Input() followButton: FollowButton;
    @Input() more: Link;
    @Input() placeholder = false;

    expectedCount = 4;

    playlistForTopicItem(topicItem: ApiTopicItem) {
        if (topicItem.type === 'vod') {
            return topicItem.vod_type === 'episode' ? 'episodes' : 'clips';
        } else if (topicItem.type === 'article') {
            return 'articles';
        }

        return 'clips';
    }

    commentTopicForTopicItem(topicItem: ApiTopicItem) {
        if (topicItem.type === 'vod')
            return `vod_${topicItem.vod_id}`;
        if (topicItem.type === 'article')
            return `article_${topicItem.article_id}`;

        return null;
    }

    badgeTypeForTopicItem(topicItem: ApiTopicItem) {
        if (topicItem.type === 'vod') {
            return topicItem.vod_type;
        } else if (topicItem.type === 'article') {
            return 'article';
        }

        return 'clip';
    }

    isValidTitle(title: string) {
        if (!title)
            return false;
        return title.trim() !== '';
    }

    linkForTopicItem(topicItem: ApiTopicItem) {
        if (topicItem.type === 'vod') {
            return `/watch/${topicItem.brand_id}/${topicItem.production_id}/${topicItem.vod_type}s/${topicItem.vod_id}`;
        } else if (topicItem.type === 'article') {
            return this.articlesService.getArticleUrlFromTopicItemSync(topicItem);
        }

        // uh oh
        return `javascript:;`;
    }

}